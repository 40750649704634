import React from "react"
import Layout from "../components/layout"


export default function Cookies() {
    return (
        <Layout>
            <h1>cookies</h1>
            <p>Welcome to my Gatsby site.</p>
        </Layout>
    )
}